<template>
  <div class="settings">
    <v-container>
      <div class="welcome">
        <h1>Settings</h1>
      </div>

      <account-settings></account-settings>

      <profile-settings></profile-settings>

      <communication-settings></communication-settings>

    </v-container>
  </div>
</template>

<script>
import AccountSettings from "../../../components/Settings/AccountSettings.vue";
import CommunicationSettings from '../../../components/Settings/CommunicationSettings.vue';
import ProfileSettings from "../../../components/Settings/ProfileSettings.vue";
export default {
  name: "Settings",

  components: { ProfileSettings, AccountSettings, CommunicationSettings },

  created() {},

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.loading {
  text-align: center;
  padding-top: 20px;
}

.settings {
  padding: 20px;

  .welcome {
    margin-bottom: 30px;
  }
}
</style>