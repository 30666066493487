<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <h2>Your Accounts</h2>
      </v-col>
      <v-col cols="12" md="9">
        <base-card v-if="isLoading">
          <div class="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></base-card>

        <base-card v-if="!isLoading" class="overflow-hidden">
          <v-tabs v-model="selected_tab">
            <v-tab v-for="account in accounts" :key="account.id">
              {{ account.currency }} Account
            </v-tab>
          </v-tabs>
          <v-card-text
            v-if="selectedAccount"
            :key="`account-tab-${selectedAccount.id}`"
          >
            <!-- Sort Code -->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                property="payout_account_name"
                name="Payout Account Name"
                :initial-value="selectedAccount.payout_account_name"
                :endpoint="`accounts/${selectedAccount.id}`"
                type="text"
                @updated="fetch"
                :cannot-edit="selectedAccount.payout_account_name !== null"
              ></editable-field>
            </div>
            <!-- / Sort Code -->
            <!-- Sort Code -->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                property="payout_sort_code"
                name="Payout Sort Code"
                :initial-value="selectedAccount.payout_sort_code"
                :endpoint="`accounts/${selectedAccount.id}`"
                type="text"
                @updated="fetch"
                :cannot-edit="selectedAccount.payout_sort_code !== null"
              ></editable-field>
            </div>
            <!-- / Sort Code -->
            <!-- Account Number -->
            <!-- <div class="border-bottom pb-3 mb-3"> -->
            <div class="">
              <editable-field
                property="payout_account_number"
                name="Payout Account Number"
                :initial-value="selectedAccount.payout_account_number"
                :endpoint="`accounts/${selectedAccount.id}`"
                type="text"
                @updated="fetch"
                :cannot-edit="selectedAccount.payout_account_number !== null"
              ></editable-field>
            </div>
            <!-- / Account Number -->
            <!-- Payout Frequency -->
            <!-- <div class="border-bottom pb-3 mb-3">
              <editable-field
                property="payout_frequency"
                name="Payout Frequency"
                :initial-value="selectedAccount.payout_frequency"
                :endpoint="`accounts/${selectedAccount.id}`"
                type="select"
                :options="payoutFrequencyOptions"
                @updated="fetch"
              ></editable-field>
            </div> -->
            <!-- / Payout Frequency -->
            <!-- Account Type -->
            <!-- <div class="">
              <editable-field
                property="type"
                name="Account Type"
                :initial-value="selectedAccount.type"
                :endpoint="`accounts/${selectedAccount.id}`"
                type="select"
                :options="accountTypeOptions"
                @updated="fetch"
              ></editable-field>
            </div> -->
            <!-- / Account Type -->
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const PAYOUT_FREQUENCY_OPTIONS = [
  {
    value: 0,
    text: "Quarterly",
  },
  {
    value: 1,
    text: "Yearly",
  },
  {
    value: 2,
    text: "On Request",
  },
];

const ACCOUNT_TYPE_OPTIONS = [
  {
    value: 0,
    text: "Discretionary",
  },
  {
    value: 1,
    text: "Part Discretionary",
  },
  {
    value: 2,
    text: "Approval Required",
  },
];

import EditableField from "../EditableField.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { EditableField },

  data() {
    return {
      selectedTab: null,
      payoutFrequencyOptions: PAYOUT_FREQUENCY_OPTIONS,
      accountTypeOptions: ACCOUNT_TYPE_OPTIONS,
    };
  },

  created() {
    if (!this.accounts.length) {
      this.fetch();
    }
  },

  methods: {
    ...mapActions("accounts", {
      fetch: "fetch",
      selectAccountId: "selectAccountId",
    }),
  },

  computed: {
    ...mapGetters("accounts", {
      isLoading: "isLoading",
      accounts: "accounts",
      selectedAccountId: "selectedAccountId",
      selectedAccount: "selectedAccount",
    }),

    selected_tab: {
      get() {
        if (this.selectedAccount) {
          return this.accounts.indexOf(this.selectedAccount);
        }
        return null;
      },
      set(val) {
        let account = this.accounts[val];
        if (account) {
          this.selectAccountId(account.id);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding: 25px;
}

h2 {
  font-size: 1.5rem;
}

.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}
</style>