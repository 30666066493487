<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <h2>Communication Preferences</h2>
      </v-col>
      <v-col cols="12" md="9">
        <base-card>
          <v-card-text>
            <!-- Notify by SMS -->
            <div class="pb-3">
              <editable-field
                property="notify_by_sms"
                name="Notify by SMS?"
                :initial-value="user.notify_by_sms"
                endpoint="profile"
                type="select"
                :options="booleanOptions"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Notify by SMS -->
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditableField from "../EditableField.vue";
import { mapGetters, mapActions } from "vuex";

const BOOLEAN_OPTIONS = [
  {
    value: 0,
    text: "No"
  },
  {
    value: 1,
    text: "Yes"
  }
];

export default {
  data() {
    return {
      booleanOptions: BOOLEAN_OPTIONS
    }
  },
  components: { EditableField },
  methods: {
    ...mapActions("auth", {
      reloadUser: "reloadUser",
    }),
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    })
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.5rem;
}

.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}
</style>