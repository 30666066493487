<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <h2>Personal Details</h2>
      </v-col>
      <v-col cols="12" md="9">
        <base-card>
          <v-card-text>
            <!-- Name-->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                property="name"
                name="Name"
                :initial-value="user.name"
                endpoint="profile"
                type="text"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Name -->
            <!-- Email-->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                property="email"
                name="Email"
                :initial-value="user.email"
                endpoint="profile"
                type="email"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Email -->
            <!-- Phone-->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                property="phone_number"
                name="Phone"
                :initial-value="user.phone_number"
                endpoint="profile"
                type="tel"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Phone -->
            <!-- Password-->
            <div>
              <editable-field
                property="password"
                name="Password"
                :initial-value="user.password"
                endpoint="profile"
                type="password"
                :confirm-current="true"
                :confirmed="true"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Password -->
          </v-card-text>
        </base-card>

        <base-card class="mt-6">
          <v-card-text>
            <!-- Address Line One-->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                :cannot-edit="true"
                property="address_line_one"
                name="Address Line One"
                :initial-value="user.address_line_one"
                endpoint="profile"
                type="text"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- /Address Line One -->

            <!-- Address Line Two-->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                :cannot-edit="true"
                property="address_line_two"
                name="Address Line Two"
                :initial-value="user.address_line_two"
                endpoint="profile"
                type="text"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- /Address Line Two -->

            <!-- City-->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                :cannot-edit="true"
                property="city"
                name="City"
                :initial-value="user.city"
                endpoint="profile"
                type="text"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / City -->

            <!-- Postcode -->
            <div class="border-bottom pb-3 mb-3">
              <editable-field
                :cannot-edit="true"
                property="postcode"
                name="Postcode"
                :initial-value="user.postcode"
                endpoint="profile"
                type="text"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Postcode -->

            <!-- Country -->
            <div class="">
              <editable-field
                :cannot-edit="true"
                property="country"
                name="Country"
                :initial-value="user.country"
                endpoint="profile"
                type="text"
                @updated="reloadUser"
              ></editable-field>
            </div>
            <!-- / Country -->
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditableField from "../EditableField.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { EditableField },
  methods: {
    ...mapActions("auth", {
      reloadUser: "reloadUser",
    }),
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.5rem;
}

.border-bottom {
  border-bottom: 1px solid #efecf3 !important;
}
</style>